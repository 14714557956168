import { Button, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import Iconify from "../Iconify";

export default function ButtonWithIcon({
  onClick,
  title,
  icon,
  disabled,
  variant,
  children,
  backgroundColor,
  color,
  ...props
}) {
  return (
    <Button
      onClick={onClick}
      variant={variant ? variant : "contained"}
      style={{height: 'fit-content'}}
      disabled={disabled}
      sx={
        disabled
          ? {
              background: palette.secondary.dark,
              borderRadius: "12px",
              color: "#fdfdfd",
              display: "flex",
              justifyContent: "center",
            }
          : {
              backgroundColor: backgroundColor,
              background: backgroundColor
                ? backgroundColor
                : palette.gradients.custom,
              borderRadius: "12px",
              color: color ? color : backgroundColor ? palette.primary.main : "#fdfdfd",
              display: "flex",
              justifyContent: "center",
            }
      }
      {...props}
    >
      <Iconify
        icon={icon}
        sx={{
          width: 18,
          height: 18,
          ml: 1,
          display: { xs: "none", sm: "flex" },
        }}
      />
      {title && (
        <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 12, sm: 14 }}>
          {title}
        </Typography>
      )}
      {children}
    </Button>
  );
}
