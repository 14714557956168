import {
  Badge,
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyBox from "../../../../components/EmptyBox";
import Scrollbar from "../../../../components/Scrollbar";
import { getDetailReportBulkWaba, getReportBulkWaba, stopBulkMessage } from "../../../../store/report/reportAction";
import palette from "../../../../theme/palette";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import { resetReportMessageStatus, setBulkWabaAll } from "../../../../store/report/reportSlice";
import DetailView from "../components/detail-view";
import toast from "react-hot-toast";

const tableCellList = ["Bulk ID", "Device", "Date", "Status", "Action"];

const tableCellDetail = ["No", "Phone Number", "Note", "Status", "Time"];

export default function BulkWaba() {
  const dispatch = useDispatch();
  const { bulkWabaAll, bulkWabaStatusAll, isDetail } = useSelector((state) => state.report);

  const [detail, setDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handeGetReportBulkWaba = async (data) => {
    setIsLoading(true);

    await dispatch(getReportBulkWaba(data));

    setIsLoading(false);
  };

  const handleExportReport = async () => {
    // toast.loading("Please wait...");

    // const dataJSON = {
    //   id: detail,
    // };

    // await dispatch(exportReport(dataJSON)).then((res) => {
    //   if (res.meta.requestStatus === "fulfilled") {
    //     toast.dismiss();
    //     toast.success("Export Report Successfully ...");
    //   } else {
    //     toast.dismiss();
    //     toast.error("Export Report Failed ...");
    //   }

    //   const url = window.URL.createObjectURL(new Blob([res.payload]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "Komers Report WhatsApp Schedule.xlsx");
    //   document.body.appendChild(link);
    //   link.click();
    //   link.parentNode.removeChild(link);
    // });
  };

  const buttonGroupDetail = [
    {
      title: "Export",
      icon: null,
      prev: () => handleExportReport(),
    },
  ];

  const handleGetWabaStatusAll = async (item) => {
    setIsLoading(true);

    await dispatch(getDetailReportBulkWaba({ bulk_id: item }));

    setIsLoading(false);
  };

  useEffect(() => {
    handeGetReportBulkWaba();
  }, []);

  useEffect(() => {
    if (detail) {
      handleGetWabaStatusAll(detail);
    }
  }, [detail]);

  useEffect(() => {
    return () => {
      dispatch(resetReportMessageStatus());
    };
  }, []);

  useEffect(() => {
    setDetail(null);
  }, [isDetail]);

  const actionStoppedStatus = ['pending', 'sending', 'paused']

  const handleStopBulkMessage = useCallback(async (bulk_id, status) => {
    if (!actionStoppedStatus.find(action_status => action_status === status)) {
      return
    }
    toast.loading('Please wait...')
    const result = await dispatch(stopBulkMessage({ bulk_id }))
    toast.remove()
    if (result?.type === 'bulk-waba/stop/fulfilled') {
      // CREATE NEW bulkWabaAll
      dispatch(setBulkWabaAll(bulkWabaAll.map((bulk_data) => {
        return {
          ...bulk_data,
          status: bulk_data?.bulk_id === result?.meta?.arg?.bulk_id ? 'stopped' : bulk_data?.status
        }
      })))
      toast.success(result?.payload?.message)
      return
    }
    toast.error(result?.payload?.message)
  }, [bulkWabaAll])

  if (detail) {
    const detailInformation = [
      {
        name: 'Total Message',
        value: bulkWabaStatusAll.length,
        icon: 'uil:comment-alt-message',
        colorIcon: "#7B7B7B"
      },
      {
        name: 'Sent',
        value: bulkWabaStatusAll.filter((e) => e.status?.toLowerCase() === "sent").length,
        icon: 'material-symbols:check',
        colorIcon: "#7B7B7B"
      },
      {
        name: 'Delivered',
        value: bulkWabaStatusAll.filter((e) => e.status?.toLowerCase() === "delivered").length,
        icon: 'material-symbols:done-all',
        colorIcon: "#7B7B7B"
      },
      {
        name: 'Read',
        value: bulkWabaStatusAll.filter((e) => e.status?.toLowerCase() === "read").length,
        icon: 'material-symbols:done-all',
        colorIcon: '#21C2C1'
      },
      {
        name: 'Failed',
        value: bulkWabaStatusAll.filter((e) => e.status?.toLowerCase() === "failed").length,
        icon: 'mdi:clock-time-four-outline',
        colorIcon: "#7B7B7B"
      },
    ]

    return (
      <DetailView
        detail={detail}
        handleExportReport={handleExportReport}
        buttonGroupDetail={buttonGroupDetail}
        allStatusData={bulkWabaStatusAll}
        detailInformation={detailInformation}
        substringTotal={12}
        isLoading={isLoading}
        tableCellDetail={tableCellDetail}
        emptyBoxTitle1="No Cloud API Database"
        emptyBoxTitle2="Generate now at"
        dateName="created_at"
        phoneNumberName="phone"
        failedName="failed"
        type="cloud-broadcast"
      />
    )
  } else {
    return (
      <Card
        style={{
          backgroundColor: "#FDFDFD",
          padding: "16px",
          borderRadius: "10px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={1}
          pl={2}
          pr={1}
          alignItems="center"
        >
          <Box>
            <Typography
              fontSize={{ xs: 20, sm: 24 }}
              fontWeight="700"
              color="#131313"
            >
              Cloud API Broadcast
            </Typography>
          </Box>
        </Box>
        {bulkWabaAll ? (
          <Scrollbar>
            <TableContainer
              component={Paper}
              style={{ backgroundColor: "#FDFDFD" }}
            >
              <Table aria-label="simple table">
                <TableHead
                  style={{
                    background: palette.gradients.custom,
                  }}
                >
                  <TableRow>
                    {tableCellList.map((item, index) => {
                      return (
                        <TableCell align="left" key={index}>
                          <Box
                            alignItems="center"
                            display="flex"
                            justifyContent={"space-between"}
                          >
                            <Typography color="#FDFDFD">{item}</Typography>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Box
                          component="img"
                          src="/images/loading.svg"
                          alt="loading"
                          height="48px"
                          sx={{ mx: "auto" }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : bulkWabaAll?.length ? (
                    bulkWabaAll?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{item?.bulk_id}</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {item?.device}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {item?.created_at}
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                            {item?.status === "stopped" ? (
                              <Badge color="error" badgeContent={item?.status}>
                                <></>
                              </Badge>
                            ) : item?.status === "success" ? (
                              <Badge color="primary" badgeContent={item?.status}>
                                <></>
                              </Badge>
                            ) : (
                              <Badge color="warning" badgeContent={item?.status}>
                                <></>
                              </Badge>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Box sx={{ mr: 1 }}>
                            <ButtonWithIcon
                              title="View"
                              icon="mdi:card-search-outline"
                              onClick={() => setDetail(item.bulk_id)}
                            />
                          </Box>
                          <Box sx={{ mr: 1 }}>
                            <ButtonWithIcon
                              title="Stop"
                              icon="nimbus:stop"
                              disabled={!actionStoppedStatus.find(status => status === item?.status)}
                              backgroundColor="#F15A28 !important"
                              color="#fdfdfd"
                              onClick={() => handleStopBulkMessage(item?.bulk_id, item?.status)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        Data Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        ) : (
          <EmptyBox title1="No Cloud API Database" />
        )}
      </Card>
    );
  }
}
